/**
 * AppEASA
 *
 * @file contactForm.js
 * @version 1.0
 * @description redux contact form' s actions
 *
 */

// loading axios
import axios from "axios";

// load pods belonging to a prospect
export const beginSendMessage = (body) => (dispatch) =>
  new Promise(async function (resolve, reject) {
    const DEBUG = false;
    DEBUG && console.log("beginSendMessage, body=", body);
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    try {
      await axios
        .post("/api/contact", body, config)
        .then((result) => {
          DEBUG && console.log("POST /api/contact result.data = ", result.data);
          if (result.status === 200) {
            resolve({ status: result.status });
          } else {
            reject({ status: false, err: "Impossible d'envoyer votre message. Veuillez essayer à nouveau plus tard." });
          }
        })
        .catch((error) => {
          //   console.log(error.response.data);
          reject({ status: false, err: error.response.data });
        });
    } catch (err) {
      const errors = err.response.data.errors;
      reject({ loginStatus: false, msg: errors });
    }
  });
