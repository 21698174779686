import React, { Component } from "react";

// Loading Material UI components
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";

export default class About extends Component {
  render() {
    return (
      <div className="about-block-wrapper" id={this.props.id}>
        <Container>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div className="about-block-overlay-wrapper">
                <p className="about-title">
                  A propos d'Enerdis Approvisionnement
                </p>
                <p className="about-content">
                  ENERDIS Approvisionnement SA a été créée en octobre 2008 par 5
                  distributeurs d’énergie afin de développer des synergies en
                  termes d’approvisionnement en énergies et en matière de coûts
                  d’infrastructures.
                </p>
                <p className="about-content">
                  Depuis lors, d’autres distributeurs romands ont rejoint la
                  société. Prévue initialement comme une plateforme
                  d’approvisionnement, elle est également rapidement devenue une
                  plateforme de partage d’idées, de stratégies, de sujets
                  d’actualités et de services à développer dans un marché de
                  l’électricité en mutation perpétuelle.
                </p>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}
