import React, { Component, Fragment } from "react";

// Loading Material UI components
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import UpdateIcon from "@material-ui/icons/Update";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import EcoIcon from "@material-ui/icons/Eco";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";

const Accordion = withStyles({
  root: {
    border: "none",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(255, 255, 255, 1)",
    // borderBottom: "none",
    // borderBottom: "solid 1px #ccc",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "0px 0",
    },
  },
  expanded: {
    "&$expanded": {
      margin: "0px 0",
      backgroundColor: "",
    },
    color: "rgba(0, 91, 151, 1);",
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
    borderBottom: "solid 1px #ccc",
  },
}))(MuiAccordionDetails);

export default class Services extends Component {
  constructor(props) {
    super(props);

    this.state = {
      services: [
        {
          title: "Gestion de portefeuilles",
          content: "Nous mettons à disposition un reporting qui à tout moment montre l'état du portefeuille (positions couvertes/ouvertes avec prix associés).",
          icon: <WorkOutlineIcon fontSize="small" />,
        },
        {
          title: "Gestion de l'approvisionnement",
          content:
            "Nous réalisons sur le marché les achats / ventes décidés par l'actionnaire et prenons en charge toutes les activités opérationnelles moyen et court terme.",
          icon: <UpdateIcon fontSize="small" />,
        },
        {
          title: "Gestion des risques",
          content: "Nous actualisons tous les jours l'exposition aux risques avec l'évolution des prix marchés et de la volatilité.",
          icon: <TrendingUpIcon fontSize="small" />,
        },
        {
          title: "Stratégie de couverture",
          content: "Nous définissons avec chaque actionnaire une stratégie de couverture adaptée à ses exigences et contraintes.",
          icon: <HowToRegIcon fontSize="small" />,
        },
        {
          title: "Support transition énergétique",
          content: "Nous permettons à chaque actionnaire d'acheter les garanties d'origine de son choix.",
          icon: <EcoIcon fontSize="small" />,
        },
        {
          title: "Offre marché",
          content: "Nous réalisons les cotations pour les clients marchés et achetons l'énergie sur le marché quand le client signe avec l'actionnaire",
          icon: <LocalOfferIcon fontSize="small" />,
        },
      ],
    };
  }
  render() {
    return (
      <Container>
        {/* Mobile */}
        <Hidden only={["lg", "xl"]}>
          <Fragment>
            <Grid container spacing={0} style={{ marginBottom: "2rem" }}>
              <Grid item>
                {this.state.services.map((service, index) => {
                  return (
                    <Accordion key={index}>
                      <AccordionSummary>
                        <Grid container spacing={0} direction="rows" justify="flex-start" alignItems="flex-start">
                          <Grid item xs={12} sm={12} md={12}>
                            <div style={{ float: "left", marginTop: "2px", opacity: 0.7, marginRight: "1rem" }}>{service.icon}</div> {service.title}
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>{service.content}</AccordionDetails>
                    </Accordion>
                  );
                })}
              </Grid>
            </Grid>
          </Fragment>
        </Hidden>
        {/* Screen */}
        <Hidden only={["xs", "sm", "md"]}>
          <Grid container spacing={0}>
            {this.state.services.map((service, index) => {
              return (
                <Grid item key={index} xs={4}>
                  <div className="service-box-wrapper" style={{}}>
                    <div className="service-icon-wrapper">{service.icon}</div>
                    <div className="service-box-content-wrapper">
                      <Grid container spacing={0}>
                        <Grid item lg={12} xl={12}>
                          <p className="service-screen-title">{service.title}</p>
                        </Grid>
                        <Grid item lg={12} xl={12}>
                          <p className="service-screen-content">{service.content}</p>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Hidden>
      </Container>
    );
  }
}
