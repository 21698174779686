import React, { Component, Fragment } from "react";

// Loading Material UI components
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

export default class Shareholder extends Component {
  render() {
    return (
      <Fragment>
        <Hidden xsDown>
          <Grid container spacing={0}>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
              <Grid container direction="row" justify="flex-start" alignItems="flex-end">
                <Grid item xs={2}>
                  <p className="year" style={{ color: `${this.props.color}` }}>
                    {this.props.year}
                  </p>
                </Grid>
                <Grid item xs={10} style={{ borderLeft: `solid 10px ${this.props.color}`, height: "7rem" }}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    style={{ height: "100%", borderBottom: "solid 1px rgba(0,0,0,0.1)" }}
                  >
                    <Grid item xs={4}>
                      <img src={this.props.logo} alt={`${this.props.name}`} className="shareholder-logo" />
                    </Grid>
                    <Grid item xs={8}>
                      <p className="shareholders-name"> {this.props.name}</p>
                      <a
                        href={`http://${this.props.url}`}
                        target="_blank"
                        rel="noreferrer"
                        className="shareholders-link"
                        style={{ color: `${this.props.color}` }}
                      >
                        {this.props.url}
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={2}></Grid>
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
            <Grid item xs={3}>
              <p className="year" style={{ color: `${this.props.color}` }}>
                {this.props.year}
              </p>
            </Grid>
            <Grid item xs={9} style={{ borderLeft: `solid 10px ${this.props.color}` }}>
              <Grid container direction="column" justify="flex-start" alignItems="flex-start" style={{ borderBottom: "solid 1px rgba(0,0,0,0.1)" }}>
                <Grid item xs={12} style={{ height: "6rem" }}>
                  <img src={this.props.logo} alt={`${this.props.name}`} className="shareholder-logo" />
                </Grid>
                <Grid item xs={12}>
                  <p className="shareholders-name"> {this.props.name}</p>
                  <a href={`http://${this.props.url}`} target="_blank" rel="noreferrer" className="shareholders-link" style={{ color: `${this.props.color}` }}>
                    {this.props.url}
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
      </Fragment>
    );
  }
}
