import React, { Component } from "react";

export default class GradientSeparator extends Component {
  render() {
    return (
      <div className={`enerdis-gradient-separator ${this.props.isFirst ? "first" : ""}`} id={this.props.id}>
        &nbsp;
      </div>
    );
  }
}
