import React, { Component } from "react";
import { Link } from "react-scroll";

// Loading Material UI components
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

// Load Enerdis Logo
import EnerdisLogo from "../resources/images/enerdis-logo.jpg";

export default class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
      headerShadow: false,
      menuItems: [
        {
          label: "Accueil",
          active: true,
          scrollTo: "sectionHome",
        },
        {
          label: "Nos services",
          active: false,
          scrollTo: "sectionServices",
        },
        {
          label: "A propos",
          active: false,
          scrollTo: "sectionAbout",
        },
        {
          label: "Nos actionnaires",
          active: false,
          scrollTo: "sectionShareholders",
        },
        {
          label: "Nous contacter",
          active: false,
          scrollTo: "sectionContact",
        },
        {
          label: "Accès EASA",
          active: false,
          ref: "https://app.enerdis-appro.ch",
        },
      ],
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.updateScroll);
  }

  componentWillUnmountt() {
    window.removeEventListener("scroll", this.updateScroll);
  }

  updateScroll = () => {
    if (window.pageYOffset > 10) {
      this.setState({ headerShadow: true });
    } else {
      this.setState({ headerShadow: false });
    }
  };

  toggleDrawer = () => {
    this.setState((prevstate) => {
      return {
        drawerOpen: !prevstate.drawerOpen,
      };
    });
  };

  render() {
    return (
      <div className={`navbar ${this.state.headerShadow ? "navbar-shadow" : ""}`}>
        <Container>
          <Grid container spacing={0} direction="row" alignItems="center" justify="space-between">
            <Grid item xs={2}>
              <img src={EnerdisLogo} alt="Enerdis Approvisionnement SA" className="navbar-logo" />
            </Grid>
            <Grid item xs={10}>
              {/* Mobile */}
              <Hidden only={["lg", "xl"]}>
                <Grid container spacing={0} direction="column" alignItems="flex-end">
                  <Grid>
                    <IconButton onClick={this.toggleDrawer}>
                      <MenuIcon fontSize="inherit" />
                    </IconButton>
                    <Drawer anchor="right" open={this.state.drawerOpen} onClose={this.toggleDrawer} className="drawermenu">
                      <ul className="navbar-drawermenulist">
                        {this.state.menuItems.map((item, index) => {
                          if (item.scrollTo) {
                            return (
                              <li key={index}>
                                <Link activeClass="active" to={item.scrollTo} spy={true} smooth={true} offset={-60} duration={500} onClick={this.toggleDrawer}>
                                  {item.label}
                                </Link>
                              </li>
                            );
                          } else if (item.ref) {
                            return (
                              <li key={index}>
                                <a href={item.ref} target="_blank" rel="noreferrer">
                                  {item.label}
                                </a>
                              </li>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </ul>
                    </Drawer>
                  </Grid>
                </Grid>
              </Hidden>
              {/* Screen */}
              <Hidden only={["xs", "sm", "md"]}>
                <Grid container spacing={0} direction="column" alignItems="flex-end">
                  <Grid></Grid>
                  <Grid>
                    <ul className="navbar-hrmenulist">
                      {this.state.menuItems.map((item, index) => {
                        if (item.scrollTo) {
                          return (
                            <li key={index}>
                              <Link activeClass="active" to={item.scrollTo} spy={true} smooth={true} offset={-80} duration={500}>
                                {item.label}
                              </Link>
                            </li>
                          );
                        } else if (item.ref) {
                          return (
                            <li key={index}>
                              <a href={item.ref} target="_blank" rel="noreferrer">
                                {item.label}
                              </a>
                            </li>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </ul>
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}
