import React, { Component } from "react";

// Loading UI components
import Shareholder from "./Shareholder";

// Loading Material UI components
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

// import shareholders' logos
import SEICLogo from "../resources/images/seic.png";
import SINLogo from "../resources/images/sin.png";
import VOELogo from "../resources/images/voe.png";
import SEVJLogo from "../resources/images/sevj.jpg";
import SIGLogo from "../resources/images/sig.jpg";
import IBMLogo from "../resources/images/ibm.png";
import YELogo from "../resources/images/sey.png";

export default class Shareholders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shareholders: [
        {
          year: "2008",
          name: "Société Électrique Intercommunale de la Côte",
          logo: SEICLogo,
          url: "seicgland.ch",
          color: "#006991",
        },
        {
          year: "2008",
          name: "Service Industriels de Nyon",
          logo: SINLogo,
          url: "sinyon.ch",
          color: "#4C565C",
        },
        {
          year: "2008",
          name: "VOé Services SA",
          logo: VOELogo,
          url: "voe.ch",
          color: "#1226AA",
        },
        {
          year: "2008",
          name: "Société Électrique de la Vallée de Joux",
          logo: SEVJLogo,
          url: "sevj.ch",
          color: "#28337E",
        },
        {
          year: "2008",
          name: "Services Industriels de Genève",
          logo: SIGLogo,
          url: "sig-ge.ch",
          color: "#FFD030",
        },
        {
          year: "2010",
          name: "Industrielle Betriebe Murten",
          logo: IBMLogo,
          url: "ibmurten.ch",
          color: "#D92924",
        },
        {
          year: "2018",
          name: "Yverdon-les-Bains Énergies",
          logo: YELogo,
          url: "yverdon-energies.ch",
          color: "#54575B",
        },
      ],
    };
  }
  render() {
    return (
      <Container style={{ marginTop: "3rem", marginBottom: "3rem" }}>
        <Grid container spacing={0} direction="column" justify="flex-start" alignItems="center">
          {this.state.shareholders.map((shareholder, index) => {
            return (
              <Shareholder
                key={index}
                year={shareholder.year}
                name={shareholder.name}
                logo={shareholder.logo}
                url={shareholder.url}
                color={shareholder.color}
              />
            );
          })}
        </Grid>
      </Container>
    );
  }
}
