import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <p className="copy">&copy; {new Date().getFullYear()} Enerdis Approvisionnement SA</p>
      </div>
    );
  }
}
