import React, { Component } from "react";
import { Slide } from "react-slideshow-image";

import imgTest from "../resources/images/bandeau_bg_web.jpg";

export default class ImageSlideShow extends Component {
  render() {
    return (
      <div>
        <img src={imgTest} alt="Enerdis Approvisionnement SA" className="slideShow-img" />
      </div>
    );
  }
}
