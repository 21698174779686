import React, { Component } from "react";
import { connect } from "react-redux";

import _ from "lodash";
import { beginSendMessage } from "../actions/contactForm";

// Loading Material UI components
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      showStatusOK: false,
      showStatusError: false,
      errorMessage: "",
    };
  }

  sendContactForm = (e) => {
    e.preventDefault();

    const body = {
      name: this.state.name,
      emailAddress: this.state.email,
      message: this.state.message,
    };

    this.props
      .beginSendMessage(body)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            name: "",
            email: "",
            message: "",
            showStatusOK: true,
            showStatusError: false,
          });
        } else {
          this.setState({
            name: "",
            email: "",
            message: "",
            errorMessage: "Impossible d'envoyer votre message. Merci d'essayer plus tard.",
            showStatusOK: false,
            showStatusError: true,
          });
        }
      })
      .catch((error) => {
        if (_.isArray(error.err.errors) && error.err.errors.length > 0) {
          this.setState({
            name: "",
            email: "",
            message: "",
            errorMessage: error.err.errors[0].msg,
            showStatusOK: false,
            showStatusError: true,
          });
        }
      });
  };

  render() {
    return (
      <div className="contact-block-wrapper">
        <Container>
          <Grid container spacing={0}>
            <Grid item xs={12} md={6}>
              {this.state.showStatusOK ? (
                <div className="contact-textfield-wrapper" style={{ width: "80%" }}>
                  <Alert
                    onClose={() => {
                      this.setState({ showStatusOK: false });
                    }}
                    severity="success"
                  >
                    Votre message a bien été envoyé. Nous vous remercions pour votre intérêt et prendrons contact avec vous dans les meilleurs délais.
                  </Alert>
                </div>
              ) : null}
              {this.state.showStatusError ? (
                <div className="contact-textfield-wrapper" style={{ width: "80%" }}>
                  <Alert
                    onClose={() => {
                      this.setState({ showStatusError: false });
                    }}
                    severity="error"
                  >
                    {this.state.errorMessage}
                  </Alert>
                </div>
              ) : null}

              <div className="contact-textfield-wrapper">
                <TextField
                  id="contactName"
                  label="Nom &amp; prénom"
                  required
                  className="contact-field"
                  value={this.state.name}
                  onChange={(e) => {
                    this.setState({ name: e.target.value });
                  }}
                />
              </div>
              <div className="contact-textfield-wrapper">
                <TextField
                  id="contactEmail"
                  label="Adresse e-mail"
                  required
                  className="contact-field"
                  value={this.state.email}
                  onChange={(e) => {
                    this.setState({ email: e.target.value });
                  }}
                />
              </div>
              <div className="contact-textfield-wrapper">
                <TextField
                  id="contactMessage"
                  label="Votre message"
                  required
                  multiline
                  className="contact-field"
                  value={this.state.message}
                  onChange={(e) => {
                    this.setState({ message: e.target.value });
                  }}
                />
              </div>
              <div className="contact-button-wrapper">
                <Button variant="contained" disableElevation onClick={this.sendContactForm}>
                  Envoyer
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <p className="contact-companyname">Enerdis Approvisionnement SA</p>
              <p className="contact-address">
                Route de Champ-Colin 6<br />
                c/o Services Industriels
                <br />
                1260 Nyon
              </p>
              <p className="contact-phone">+41 (0)22 420 79 03</p>
              <p className="contact-who">Personne de contact</p>
              <p className="contact-name">Monsieur Antoine Lechenne</p>
              <p className="contact-function">Portfolio Manager</p>
              <p className="contact-email">
                <a href="mailto:antoine.lechenne@sig-ge.ch">antoine.lechenne@sig-ge.ch</a>
              </p>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = (dispatch) => ({
  beginSendMessage: (body) => dispatch(beginSendMessage(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
