import React, { Fragment, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  // Redirect
} from "react-router-dom";

// Load redux components
import { Provider } from "react-redux";
import store from "./store";

// Loading SCSS files
import "./styles.scss";

// Load UI pages
import HomePage from "./Views/HomePage";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <Switch>
            <Route exact={true} path="/" component={HomePage} />
          </Switch>
        </Fragment>
      </Router>
    </Provider>
  );
}

export default App;
