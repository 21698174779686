import React, { Component, Fragment } from "react";

// Import Page components
import Navbar from "../components/Navbar";
import GradientSeparator from "../components/GradientSeparator";
import ImageSlideShow from "../components/ImageSlideShow";
import BlockSeparator from "../components/BlockSeparator";
import Services from "../components/Services";
import About from "../components/About";
import Shareholders from "../components/Shareholders";
import KPIs from "../components/KPIs";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

export default class HomePage extends Component {
  render() {
    return (
      <Fragment>
        <Navbar />
        <GradientSeparator isFirst={true} id="sectionHome" />
        <ImageSlideShow />
        <BlockSeparator
          background={false}
          displayLine={true}
          title="L'énergie c'est notre métier"
          subTitle="des services adaptés à vos besoins"
          id="sectionServices"
        />
        <Services />
        <About id="sectionAbout" />
        <GradientSeparator id="sectionShareholders" />
        <BlockSeparator background={true} displayLine={true} title="Nos actionnaires" subTitle="" />
        <Shareholders />
        <BlockSeparator background={true} displayLine={true} title="Chiffres clés" subTitle="des sinérgies efficaces pour l’ensemble de nos actionnaires" />
        <KPIs />
        <GradientSeparator />
        <BlockSeparator background={false} displayLine={false} title="Nous contacter" id="sectionContact" />
        <Contact />
        <GradientSeparator />
        <Footer />
      </Fragment>
    );
  }
}
