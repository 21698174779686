import React, { Component } from "react";

// Loading Material UI components
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

export default class KPIs extends Component {
  render() {
    return (
      <div className="kpis-block-wrapper">
        <Container>
          <Grid container spacing={0}>
            <Grid item xs={6} md={3}>
              <p className="kpis-value">+ 3TWh</p>
              <p className="kpis-label">par année</p>
            </Grid>
            <Grid item xs={6} md={3}>
              <p className="kpis-value">~ 5%</p>
              <p className="kpis-label">clients Suisse fournis</p>
            </Grid>
            <Grid item xs={6} md={3}>
              <p className="kpis-value">~ 65%</p>
              <p className="kpis-label">gains de foisonnement</p>
            </Grid>
            <Grid item xs={6} md={3}>
              <p className="kpis-value">+ 10 ans</p>
              <p className="kpis-label">d'expérience sur le marché Suisse</p>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}
