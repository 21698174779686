import React, { Component } from "react";

// Loading UI Components
import BlockSeparatorLine from "./BlockSeparatorLine";

// Loading Material UI Components
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

export default class BlockSeparator extends Component {
  render() {
    let displayBackgroundClass = "";
    if (this.props.background) displayBackgroundClass = "blockBackground-bg";
    return (
      <div className={`blockSeparator-wrapper ${displayBackgroundClass}`} id={this.props.id}>
        <Container>
          <Grid container spacing={0} direction="column" alignItems="center">
            {this.props.displayLine ? (
              <Grid item xs={12}>
                <BlockSeparatorLine />
              </Grid>
            ) : null}

            <Grid item>
              <p className="title">{this.props.title}</p>
            </Grid>
            <Grid item>
              <p className="subtitle">{this.props.subTitle}</p>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}
